<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Reporte: Preanálisis" cardTextFooter :exportInfo="items">
      <template v-slot:buttons>
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table :headers="headers" :items="items" :search="search" :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="20" item-key="id" class="elevation-1" dense>
        <template v-slot:[`item.debe`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.debe) }}
        </template>
        <template v-slot:[`item.fallas`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.fallas) }}
        </template>
        <template v-slot:[`item.adelantos_previos`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.adelantos_previos) }}
        </template>
        <template v-slot:[`item.entrega_bruta`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.entrega_bruta) }}
        </template>
        <template v-slot:[`item.recuperacion`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.recuperacion) }}
        </template>
        <template v-slot:[`item.adelanta`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.adelanta) }}
        </template>
        <template v-slot:[`item.entrega_final`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.entrega_final) }}
        </template>
        <template v-slot:[`item.aportacion`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.aportacion) }}
        </template>
        <template v-slot:[`item.prestamo`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.prestamo) }}
        </template>
        <template v-slot:[`item.regreso_efectivo`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.regreso_efectivo) }}
        </template>
        <template v-slot:[`item.regreso_deposito`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.regreso_deposito) }}
        </template>
        <template v-slot:[`item.regreso_total`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.regreso_total) }}
        </template>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn } from "@/components"
import services from "@/utils/services"

export default {
  name: "preanalysisReport",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
  },
  data: () => ({
    alert: {
      active: false,
      color: 'error',
      msg: '',
    },
    loading: false,
    search: '',
    headers: [
      { text: "Supervisora", align: "left", sortable: true, value: "supervisora" },
      { text: "Encargada", align: "left", sortable: true, value: "encargada" },
      { text: "Coordinadora", align: "left", sortable: true, value: "coordinadora" },
      { text: "Grupo", align: "left", sortable: true, value: "grupo" },
      { text: "Debe", align: "left", sortable: true, value: "debe" },
      { text: "Fallas", align: "left", sortable: true, value: "fallas" },
      { text: "Adelantos previos", align: "left", sortable: true, value: "adelantos_previos" },
      { text: "Entrega bruta", align: "left", sortable: true, value: "entrega_bruta" },
      { text: "Recuperación", align: "left", sortable: true, value: "recuperacion" },
      { text: "Adelanta", align: "left", sortable: true, value: "adelanta" },
      { text: "Entrega final", align: "left", sortable: true, value: "entrega_final" },
      { text: "Aportación", align: "left", sortable: true, value: "aportacion" },
      { text: "Préstamo", align: "left", sortable: true, value: "prestamo" },
      { text: "Regreso efectivo", align: "left", sortable: true, value: "regreso_efectivo" },
      { text: "Regreso depósito", align: "left", sortable: true, value: "regreso_deposito" },
      { text: "Regreso total", align: "left", sortable: true, value: "regreso_total" },
      { text: "% (Adelanto)", align: "left", sortable: true, value: "porcentaje_con_adelanto" },
      { text: "% (Sin adelanto)", align: "left", sortable: true, value: "porcentaje_sin_adelanto" }
    ],
    items: []
  }),
  mounted() {
    this.refresh()
  },
  methods: {
    formatNumberToMoney(num) {
      try {
        const aux = Number(num);
        return aux
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } catch (e) {
        return ''
      }
    },
    refresh() {
      this.loading = true
      this.axios.get(services.routes.preanalysisReport)
        .then((response) => {
          this.items = response.data.data
        })
        .catch((error) => {
          this.alert.active = true
          this.alert.color = 'error'
          this.alert.msg = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
};
</script>

<style scoped>
</style>
